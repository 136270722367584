import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@app/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormlyModule } from '@ngx-formly/core';
import {
	CoreConfigManagerService,
	I18nModule,
	ScibAppWrapperModule,
	ScibCoreModule,
	ScibMfDevHeaderModule,
	SingleSpaTools,
	loadUiKit
} from '@scib-microfrontends/angular-common';
import { registerBus } from '@scib-microfrontends/communication';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/**
 * Runs on application start.
 *
 * @param coreConfigManagerService CoreConfigManagerService instance
 * @returns {Promise} when initialization is done.
 */
export function appInitializerFactory(coreConfigManagerService: CoreConfigManagerService): () => Promise<void> {
	registerBus(environment.applicationId);
	coreConfigManagerService.updateConfig(environment);
	loadUiKit();

	return () => new Promise((resolve) => resolve());
}

@NgModule({
	imports: [
		BrowserAnimationsModule,
		CommonModule,
		AppRoutingModule,
		ScibAppWrapperModule,
		ScibMfDevHeaderModule,
		ScibCoreModule.forMainApp(environment, SingleSpaTools),
		I18nModule.forRoot({
			...environment.i18n,
			appId: environment.applicationId,
			commonTranslationFileNames: []
		}),
		StoreModule.forRoot(
			{},
			{
				runtimeChecks: {
					strictStateSerializability: true,
					strictActionSerializability: true,
					strictStateImmutability: true,
					strictActionImmutability: true,
					strictActionWithinNgZone: true,
					strictActionTypeUniqueness: true
				}
			}
		),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({ name: environment.applicationId, maxAge: 25, logOnly: environment.production }),
		FormlyModule.forRoot({
			extras: { lazyRender: true }
		})
	],
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [CoreConfigManagerService],
			multi: true
		}
	]
})
export class AppModule {}
