import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: 'backoffice',
		loadChildren: () => import('./exposedModules/employees/employees.module').then((m) => m.EmployeesModule)
	},
	{
		path: 'clients',
		loadChildren: () => import('./exposedModules/clients/clients.module').then((m) => m.ClientsModule)
	},
	{
		path: 'onboarding',
		loadChildren: () => import('@app/onboarding').then((m) => m.OnboardingModule)
	},
	{
		path: 'ntr',
		loadChildren: () => import('./ntr/ntr.module').then((m) => m.NtrModule)
	},
	{
		path: '',
		loadChildren: () => import('@app/error').then((m) => m.ErrorModule)
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			paramsInheritanceStrategy: 'always',
			onSameUrlNavigation: 'reload'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
