import { CoreAppConfig } from '@scib-microfrontends/angular-common';

const LANG_KEY = 'assets/i18n/${lang}/';
export const commonEnvironment: CoreAppConfig = {
	configUrl: 'config/dev.json',
	useMock: false,
	production: false,
	uiKitSource: 'cdn',
	uiKitEnvironment: 'certification',
	applicationId: 'mfAngularDynamicDiscounting',
	appImage: 'img/icons/app-image.svg',
	ngrxPrefix: 'dyndis',
	i18n: {
		titleKey: 'title',
		descriptionKey: 'subtitle',
		scope: {
			forRoot: LANG_KEY,
			forChild: LANG_KEY,
			common: LANG_KEY
		},
		default: 'en-GB',
		langs: ['en-GB', 'es-ES'],
		commonTranslationFileNames: ['common'],
		debug: false
	}
};
